<template>
  <div class="relative h-full">
    <label v-show="placeholder && showPlaceholder" for="input" class="block text-sm font-medium text-gray-700 dark:text-gray-400 text-left">
      {{placeholder}}
    </label>
        <div class="mt-1 relative h-full">
          <textarea :placeholder="placeholder" @input="input" @change="validateInput" v-model="inputValue"
                    class="dark:bg-gray-750 shadow-sm block w-full h-full focus:ring-0 sm:text-sm border border-gray-300 focus:border-gray-300 rounded-md"
                    :class="errors && errors.length > 0 ? 'border-red-300 text-red-900 placeholder-red-300' : 'border-gray-300 dark:border-gray-500 dark:text-gray-200'"
                    :rows="rows"
          />
            <p v-if="errors" class="mt-2 text-sm text-red-600 absolute -bottom-5 left-0" id="input2-error">{{errors[0]}}</p>
        </div>

  </div>
</template>

<script>
export default {
  name: "Textarea",
  data() {
    return {
      inputValue: null,
    }
  },
  props: {
    modelValue: {},
    label: {
      type: String,
      default: null
    },
      rows: {
          default: 5
      },
    placeholder: {
      type: String,
      default: null
    },
    errors: {
      type: Array,
      delault: [],
    },
    showPlaceholder: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    modelValue: function () {
      this.inputValue = this.modelValue;
    }
  },
  mounted() {
    this.inputValue = this.modelValue;
  },
  emits: ['update:modelValue'],
  methods: {
    input() {
      this.$emit('update:modelValue', this.inputValue) // previously was `this.$emit('input', title)`
    },
  }
}
</script>

<style>

</style>