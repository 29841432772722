
class Calculations {

    static defaultVatRate() {
        return 21
    }

    static getPriceWithDiscount( price, discount ) {

        var totalDiscount = price * ( discount / 100 )
        return +(price - totalDiscount).toFixed(2)
    }

    static getAllItemSum(items) {

        return items.reduce(function(prev, cur) {
            return prev + (+cur.total_with_readings);
        }, 0);
    }

    static getItemSumByParamName(items, param) {
        return items.reduce(function(prev, cur) {
            return prev + (+cur[param]);
        }, 0);
    }

    static getVat(total, vatRate) {

        return total * ( vatRate / 100)
    }

    static getReadingData(reading, readingDate) {

        if(!reading.document_id) {

            reading.quantity = reading.newReading
                ? reading.newReading - reading.reading
                : 0
        }

        reading.reading_date = readingDate
        reading.total = reading.quantity * reading.price
        reading.total_with_discount = this.getPriceWithDiscount(reading.total, reading.discount)

        return reading
    }

    static getItemsTotalWithReadingsAndDiscounts(item) {

        let readingsTotal = item.readings.reduce(function(prev, cur) {
            return prev + (+cur.total_with_discount);
        }, 0);

        return item.total_with_discount + readingsTotal
    }

}

export default Calculations
