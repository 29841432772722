<template>
    <div v-if="form" class="block hover:bg-gray-50 dark:hover:bg-gray-770 border-t border-gray-200 dark:border-gray-700 w-full">
      <div class="px-4 py-4 sm:px-6">
        <div class="flex">

          <div class="flex flex-wrap">
            <template v-if="item.image_url">
              <div class="col-span-1 pr-4">
                <img :src="item.image_url" alt="BTE" class="max-w-[75px] max-h-[75px] w-full object-contain">
              </div>
            </template>

            <ItemText title="Preces nosaukums" :text="item.name" />
            <ItemText title="Preces kods" :text="item.sku" />
            <ItemText title="Kategorija" :text="item.category.name" />
              <template v-if="item && item.supplier_catalog_item">
                  <ItemText title="Piegādātāja kods" :text="item.supplier_catalog_item.external_id" />
              </template>

              <template v-if="!item.supplier_catalog_item">
                  <div class="w-60 pt-2">
                      <Input :showPlaceholder="false" name="supplier_external_id" placeholder="Piegādātāja kods"
                             v-model="form.supplier_external_id" :errors="errors[`supplier_external_id-${form.id}`]" />
                  </div>
              </template>

            <div class="w-full flex flex-wrap gap-3 mt-4">
              <div class="w-44">
                <Input type="number" name="price" placeholder="Cena bez PVN" v-model="form.price" @change="calculateTotals" :errors="errors[`price-${form.id}`]" />
              </div>

              <div class="w-44">
                <Input type="number" name="quantity" placeholder="Skaits" v-model="form.quantity" @change="calculateTotals" :errors="errors[`quantity-${form.id}`]" />
              </div>

              <div class="w-44">
                <Input type="number" name="discount" placeholder="Atlaide %" v-model="form.discount" @change="calculateTotals" />
              </div>

              <div class="w-44">
                <Input type="number" name="vatRate" placeholder="PVN %" v-model="form.vatRate" @change="calculateTotals" />
              </div>

              <div class="w-44">
                <Input type="text" name="regional_code" placeholder="Reģionālais kods" v-model="form.regional_code" @change="calculateTotals" />
              </div>
            </div>

            <div class="w-full flex flex-wrap gap-3 mt-4">
              <div class="w-44">
                <ItemText title="Summa(bez PVN) EUR" :text="item.totalWithDiscount" />
              </div>
              <div class="w-44">
                <ItemText title="PVN EUR" :text="item.totalVat" />
              </div>
              <div class="w-44">
                <ItemText title="Summa(ar PVN) EUR" :text="item.totalWithVat" />
              </div>
            </div>

            <template v-if="customer && customer.branches.length > 0">
              <Select v-model="form.branchId" :items="customer.branches" placeholder="Klienta filiāle" />
            </template>

            <template v-if="item.use_unique_codes">
              <div class="w-full mt-4 pb-4 mb-4">
                  <Textarea name="unique_codes" placeholder="IMEI / Serial nr / Kodi"
                            v-model="form.unique_ids" @change="calculateTotals"
                            rows="3"
                            :errors="errors[`unique_ids-${item.uuidv4}`] ? errors[`unique_ids-${item.uuidv4}`] : getResponseError()"
                  />
              </div>
            </template>

          </div>

          <div class="ml-auto">
            <p class="mt-2 flex items-center text-sm font-semibold text-gray-900 dark:text-gray-300 sm:mt-0">
              <Button icon="close" @click="$emit('remove-product', item)" />
            </p>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import Calculations from "@/services/Calculations/calculations";
import ItemText from "@/components/Components/ItemText"
import Input from "@/components/Components/Input"
import Select from "@/components/Components/Select"
import Textarea from "../../Components/Textarea";

export default {
  name: "SingleProcurementItemForm",
  components: {
      Textarea,
    ItemText,
    Input,
    Select,
  },
  props: ['item', 'customer', 'itemIndex', 'procurementItemsErrors'],
  data: () => ({
    form: null,
    total: 0,
  }),
  computed: {
    ...mapGetters({
      errors: "errors"
    })
  },
  mounted() {
    this.form = this.item
    if (this.item.use_unique_codes) {
      this.form.unique_ids = ''
    }
    if (this.item.category.reverse_vat) {
      this.form.vatRate = 0
    }

    this.calculateTotals()
  },
  methods: {
    calculateTotals() {
      const discountRate =  +this.item.discount / 100
      this.form.total = (this.form.price * this.form.quantity).toFixed(2)
      this.form.totalDiscount = (this.form.total * discountRate).toFixed(2)
      this.form.totalWithDiscount = (this.form.total - this.form.totalDiscount).toFixed(2)
      this.form.totalVat = (Calculations.getVat(this.form.totalWithDiscount, this.form.vatRate)).toFixed(2)
      this.form.totalWithVat = ((this.form.totalWithDiscount * 1)  + +this.form.totalVat).toFixed(2)

      this.$emit('item', this.form)
    },
    getResponseError() {
      let error = []
      if (this.procurementItemsErrors) {

        if(this.procurementItemsErrors[`procurement_items.${this.itemIndex}.catalog_item_id`]) {
          error = this.procurementItemsErrors[`procurement_items.${this.itemIndex}.catalog_item_id`]
        }

        if(this.procurementItemsErrors[`procurement_items.${this.itemIndex}.supplier_external_id`]) {
          error = this.procurementItemsErrors[`procurement_items.${this.itemIndex}.supplier_external_id`]
        }
      }

      return error ? error : []
    }
  }
}
</script>

<style>
</style>