<template>
    <Content :search="false">
        <template v-slot:beforeSearch>
            <div class="flex items-center text-lg font-semibold dark:text-gray-300">
                <Button icon="arrow-left" @click="goBack"/>
                <h2>Jauns iepirkums</h2>
            </div>
        </template>

        <template v-slot:content>
            <form v-if="inputData" class="space-y-6 w-full bg-white dark:bg-gray-750 p-4 rounded-lg">

                <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div class="col-span-1 md:col-span-2">
                        <h2 class="text-xl font-bold dark:text-gray-300">Iepirkuma dati</h2>
                    </div>

                    <div class="col-span-1">
                        <Autocomplete placeholder="Piegādātājs" v-model="form.supplier" :errors="errors.supplier"
                                      :items="inputData.suppliers" :showItems="true"/>
                    </div>

                    <div class="col-span-1">
                        <Input placeholder="Piegādādāja PPR nr." v-model="form.supplierUUID"
                               :errors="errors.supplierUUID"/>
                    </div>

                    <div class="col-span-1">
                        <Select v-model="form.warehouse" :errors="errors.warehouse" :items="inputData.warehouses"
                                placeholder="Noliktava"/>
                    </div>

                    <div class="col-span-1">
                        <Input type="date" placeholder="Dokumenta datums" v-model="form.procurementDate"
                               :errors="errors.procurementDate"/>
                    </div>

                    <div class="col-span-1">
                      <Input type="date" placeholder="Apmaksas termiņš" v-model="form.dueDate"
                             :errors="errors.dueDate"/>
                    </div>
                </div>

                <template v-if="form.supplier">
                    <div
                        class="grid grid-cols-1 md:grid-cols-2 gap-6 border-t border-gray-200 dark:border-gray-700 pt-4">
                        <div class="col-span-1 md:col-span-2">
                            <h2 class="text-xl font-bold dark:text-gray-300">Preces</h2>
                        </div>

                        <div class="col-span-1">
                            <div class="grid grid-cols-1 md:grid-cols-2 pt-2">
                                <div class="col-span-1 flex gap-3 items-center mt-5">
                                    <Badge text="Pievienot preces" class="mb-2 md:mb-0"
                                           :class="uploadExcel ? 'bg-gray-200 text-gray-400' : 'bg-green-400 text-green-100'"/>
                                    <Switch v-model="uploadExcel" color="bg-green-400"/>
                                    <Badge text="Augšupielādēt excel failu" class="mb-2 md:mb-0"
                                           :class="!uploadExcel ? 'bg-gray-200 text-gray-400' : 'bg-green-400 text-green-100'"/>
                                </div>
                            </div>
                        </div>

                        <template v-if="uploadExcel">
                            <div class="col-span-1 md:col-span-2">
                                <FileInput placeholder="Pievienot failu" @files="getFiles" :errors="errors.excel"
                                           :multiple="false"/>
                            </div>

                            <template v-if="excelErrors">
                                <div class="col-span-1 md:col-span-2">
                                    <div
                                        class="w-full bg-gray-200 bg-opacity-70 dark:bg-gray-800 pl-4 pr-1 py-2 rounded-lg">
                                        <h3 class="text-xl font-semibold text-red-500 mb-3">Kļūdas</h3>
                                        <div class="w-full rounded-lg max-h-72 overflow-auto">
                                            <template v-for="(error, index) in excelErrors" :key="index">
                                                <template v-for="(errorMessage, i) in error" :key="i">
                                                    <p class="text-red-600 dark:text-red-200">{{ errorMessage }}</p>
                                                </template>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </template>

                        </template>


                        <template v-if="!uploadExcel">


                            <template v-if="orderItems && orderItems.length">
                                <div class="col-span-1 md:col-span-2">
                                    <template v-for="(item, index) in orderItems" :key="index">
                                        <SingleProcurementItemForm :item="item" @remove-product="removeOrderItem(index)"
                                                 :itemIndex="index"
                                                 :procurementItemsErrors="procurementItemsErrors"/>
                                    </template>
                                </div>
                            </template>

                            <div class="col-span-1 md:col-span-2">
                                <Checkbox text="Vairāki kodi" v-model="multipleCodes"/>
                            </div>

                            <template v-if="!multipleCodes">
                                <div class="col-span-1">
                                    <Autocomplete placeholder="Prece"
                                                  url="/api/fetch_catalog_items"
                                                  @autocompleteItem="getCatalogItem"
                                                  :additionalFields="['sku']"
                                                  :params="{ supplier_id: form.supplier.id }"
                                                  :clearOnClick="true"/>
                                </div>
                            </template>

                            <template v-if="multipleCodes">
                                <div class="col-span-1 md:col-span-2">
                                    <Input placeholder="Preču kodi" v-model="productCodes" @input="getProductCodes"/>
                                </div>

                                <template v-if="multipleCodesErrors">
                                    <div class="col-span-1 md:col-span-2">
                                        <div
                                            class="w-full bg-gray-200 bg-opacity-70 dark:bg-gray-800 pl-4 pr-1 py-2 rounded-lg">
                                            <h3 class="text-xl font-semibold text-red-500 mb-3">
                                                {{ multipleCodesErrors.error_message }}</h3>
                                            <div class="w-full rounded-lg max-h-72 overflow-auto">
                                                <template v-for="(error, index) in multipleCodesErrors.product_codes"
                                                          :key="index">
                                                    <p class="text-red-600 dark:text-red-200">{{ error }}</p>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </template>

                        </template>

                        <div class="col-span-1 md:col-span-2">
                            <h2 class="text-xl font-bold dark:text-gray-300">Piezīmes</h2>
                        </div>

                        <div class="col-span-1 md:col-span-2" style="height: 100px">
                            <Textarea placeholder="Piezīmes" v-model="form.notes" :errors="errors.notes"/>
                        </div>

                    </div>

                </template>
                <template v-if="!uploadExcel">
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-6" v-observe-visibility="visibilityChanged">
                        <div class="col-span-1 md:col-span-2 flex flex-col justify-end">
                            <div class="flex justify-end mb-2 dark:text-gray-300">
                                <span class="mr-2">Summa EUR:</span>
                                <span class="font-bold">{{ total.toFixed(2) }}</span>
                            </div>

                            <div class="flex justify-end mb-2 dark:text-gray-300">
                                <span class="mr-2">PVN EUR:</span>
                                <span class="font-bold">{{ totalVat.toFixed(2) }}</span>
                            </div>

                            <div class="flex justify-end mb-2 dark:text-gray-300">
                                <span class="mr-2">Kopā apmaksai EUR:</span>
                                <span class="font-bold">{{ totalWithVat.toFixed(2) }}</span>
                            </div>
                        </div>
                    </div>
                </template>

                <div v-show="showSnackbar && !uploadExcel"
                     class="bg-main4 p-5 fixed bottom-10 right-10 rounded-lg shadow-xl">
                    <div class="flex justify-end">
                        <span class="mr-3 text-white">Summa EUR: </span>
                        <p class="font-bold text-white">{{ total.toFixed(2) }}</p>
                    </div>
                </div>

                <div class="pt-5">
                    <div class="flex justify-end">
                        <button @click="goBack" type="button"
                                class="bg-white dark:bg-gray-750 py-2 px-4 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 mr-2">
                            Atcelt
                        </button>

                        <template
                            v-if="orderItems.length > 0 || (uploadExcel && form.excel && Object.keys(form.excel).length)">
                            <template v-if="!loading">
                                <button type="button"
                                        class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out"
                                        @click.prevent="submit">
                                    Saglabāt
                                </button>
                            </template>
                            <template v-else>
                                <Loading/>
                            </template>
                        </template>
                    </div>
                </div>

            </form>
        </template>
    </Content>
</template>

<script>
import {v4 as uuidv4} from "uuid";
import {mapGetters} from "vuex";
import Calculations from "@/services/Calculations/calculations";
import Select from "@/components/Components/Select";
import Switch from "@/components/Components/Switch";
import Badge from "@/components/Components/Badge";
import Autocomplete from "@/components/Components/Autocomplete";
import Input from "@/components/Components/Input";
import Textarea from "@/components/Components/Textarea";
import FileInput from "@/components/Components/FileInput";
import Loading from "@/components/Components/Loading";
import Validation from "@/modules/Validation";
import SingleProcurementItemForm from "@/components/Stock/Procurement/SingleProcurementItemForm"
import Checkbox from "@/components/Components/Checkbox";

export default {
    name: "CreateProcurement",
    components: {
        Select,
        Switch,
        Autocomplete,
        Input,
        Textarea,
        FileInput,
        Badge,
        Loading,
        SingleProcurementItemForm,
        Checkbox,
    },
    data: () => ({
        multipleCodes: false,
        uploadExcel: false,
        excelErrors: null,
        multipleCodesErrors: null,
        productCodes: null,
        form: {
            excel: null,
            supplier: null,
            supplierUUID: null,
            warehouse: null,
            procurementDate: new Date().toISOString().substr(0, 10),
            dueDate: '',
            isAccepted: false,
            useCourier: false,
            notes: "",
        },
        vatRate: 21,
        catalogItem: null,
        orderItems: [],
        showSnackbar: true,
        snackbar: true,
        timeout: 0,
        formValidation: {},
        procurementItemsErrors: {}
    }),
    computed: {
        ...mapGetters({
            inputData: "procurementInputData",
            loading: "loading",
            errors: "errors",
        }),
        total() {
            return Calculations.getItemSumByParamName(this.orderItems, 'totalWithDiscount');
        },
        totalVat() {
            return Calculations.getItemSumByParamName(this.orderItems, 'totalVat');
        },
        totalWithVat() {
            return this.total + this.totalVat;
        },

    },
    created() {
        this.$store.dispatch('getProcurementsInputData')
        this.$store.dispatch('setErrors', {})
    },
    watch: {
        catalogItem() {
            if (this.catalogItem) {
                this.addCatalogItemToOrderItems()
                this.catalogItem = null
            }
        },
        'form.supplier'() {
            this.orderItems = []
            if (this.form.supplier) {
                this.vatRate = this.form.supplier.is_export ? 0 : this.inputData.vat_rate
            }
        },
        multipleCodes() {
            this.productCodes = null
        }
    },
    methods: {
        setItemErrors() {
            this.errorMessage = null
            this.formValidation = {
                supplier: {
                    rules: ['required']
                },
                warehouse: {
                    rules: ['required']
                },
                procurementDate: {
                    rules: ['required']
                },
                supplierUUID: {
                    rules: ['required']
                },
            }

            if (this.uploadExcel) {
                this.formValidation.excel = {
                    rules: ['required']
                }
            } else {
                this.orderItems.map((item) => {
                    if (item.use_unique_codes) {
                        this.formValidation[`unique_ids-${item.uuidv4}`] = {rules: ['required']};
                    }
                })
            }
        },
        getItemsValidationValues() {
            this.setItemErrors()
            let form = {}
            form = this.form

            if (!this.uploadExcel) {
                this.orderItems.map((item) => {
                    if (item.use_unique_codes) {
                        form[`unique_ids-${item.uuidv4}`] = item.unique_ids
                    }
                })
            }
            return form
        },
        getFiles(files) {
            this.form.excel = files
        },
        getProductCodes() {
            this.multipleCodesErrors = null

            this.$store.dispatch("fetchMultipleCatalogItems", {
                products: this.productCodes,
                supplier_id: this.form.supplier.id,
            }).then(response => {
                this.addCatalogItemsToOrderItems(response.data.data)
                this.multipleCodes = false
            }).catch(error => {
                this.multipleCodesErrors = error.response.data
            })
        },
        addCatalogItemsToOrderItems(items) {
            items.forEach(item => {
                item.uuidv4 = uuidv4()
                item.vatRate = item.category.reverse_vat ? 0 : this.vatRate
                item.quantity = 1
                item.discountRate = 0
                item.discount = 0
                item.totalDiscount = 0
                item.total = 0
                item.totalWithDiscount = 0
                item.totalVat = 0
                item.totalWithVat = 0
                item.price = item.item_value
                item.vat = 0
                item.regional_code = item.default_regional_code
                item.supplier_external_id = item.supplier_catalog_item ? item.supplier_catalog_item.external_id : ''

                this.orderItems.push(item)
            })
        },

        getCatalogItem(value) {
            this.catalogItem = value
        },
        addCatalogItemToOrderItems() {
            this.catalogItem.uuidv4 = uuidv4()
            this.catalogItem.vatRate = this.catalogItem.category.reverse_vat ? 0 : this.vatRate
            this.catalogItem.quantity = 1
            this.catalogItem.discountRate = 0
            this.catalogItem.discount = 0
            this.catalogItem.totalDiscount = 0
            this.catalogItem.total = 0
            this.catalogItem.totalWithDiscount = 0
            this.catalogItem.totalVat = 0
            this.catalogItem.totalWithVat = 0
            this.catalogItem.price = this.catalogItem.item_value
            this.catalogItem.vat = 0
            this.catalogItem.regional_code = this.catalogItem.default_regional_code
            this.catalogItem.supplier_external_id = this.catalogItem.supplier_catalog_item ? this.catalogItem.supplier_catalog_item.external_id : ''

            this.orderItems.push(this.catalogItem)
        },
        removeOrderItem(index) {
            this.orderItems.splice(index, 1);
        },
        formatOrderItems() {
            return this.orderItems.map(orderItem => {
                const unique_ids = orderItem.unique_ids ? orderItem.unique_ids : null
                return {
                    catalog_item_id: orderItem.id,
                    price: orderItem.price,
                    quantity: orderItem.quantity,
                    discount_rate: orderItem.discount,
                    discount: orderItem.totalDiscount,
                    vat_rate: orderItem.vatRate,
                    unique_ids: unique_ids,
                    supplier_external_id: orderItem.supplier_external_id,
                    regional_code: orderItem.regional_code ? orderItem.regional_code : ""
                }
            })
        },
        goBack() {
            if (confirm("Vai esi drošs? Visi ievadītie lauki tiks pazaudēti")) {
                this.$router.go(-1);
            }
        },
        visibilityChanged(isVisible) {
            this.showSnackbar = !isVisible;
        },
        submit() {
            if (this.uploadExcel) {
                this.submitExcel()
            } else {
                this.submitForm()
            }
        },
        submitForm() {
            this.$Progress.start()

            const form = this.getItemsValidationValues()
            if (Validation(this.formValidation, form)) {
                this.$store.dispatch('createNewProcurement', {
                    supplier_id: this.form.supplier.id,
                    supplier_uuid: this.form.supplierUUID,
                    warehouse_id: this.form.warehouse.id,
                    procurement_date: this.form.procurementDate,
                    due_date: this.form.dueDate,
                    procurement_items: this.formatOrderItems(),
                    is_accepted: this.form.isAccepted,
                    notes: this.form.notes,
                    use_courier: this.form.useCourier
                }).catch(error => {
                    this.procurementItemsErrors = error.response.data.errors
                    this.$Progress.fail()
                })
            } else this.$Progress.fail()
        },
        submitExcel() {
            let formData = new FormData();
            if (this.form.excel && this.form.excel.length > 0) {
                for (var i = 0; i < this.form.excel.length; i++) {
                    let file = this.form.excel[i];
                    formData.append("excel", file);
                }
            }

            const form = this.getItemsValidationValues()
            this.$Progress.start();
            if (Validation(this.formValidation, form)) {

                formData.append("procurement_date", this.form.procurementDate)
                formData.append("due_date", this.form.dueDate)
                formData.append("supplier_id", this.form.supplier.id)
                formData.append("supplier_uuid", this.form.supplierUUID ? this.form.supplierUUID : '')
                formData.append("warehouse_id", this.form.warehouse.id)
                formData.append("notes", this.form.notes)

                this.$store.dispatch("createNewProcurementFromExcel", formData)
                    .catch((error) => {
                        this.excelErrors = error.response.data.errors
                    })
            } else {
                this.$Progress.fail();
            }
        },
    }
}
</script>