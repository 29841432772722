<template>
  <Switch v-model="enabled" class="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-0">
    <span class="sr-only">Use setting</span>
    <span aria-hidden="true" class="pointer-events-none absolute w-full h-full rounded-md" />
    <span aria-hidden="true" :class="[enabled ? color : 'bg-gray-200', 'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200']" />
    <span aria-hidden="true" :class="[enabled ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200']" />
  </Switch>
</template>

<script>
import { Switch } from '@headlessui/vue'

export default {
  components: {
    Switch,
  },
  data() {
    return {
      enabled: false,
    }
  },
  props: {
    modelValue: {
      type: Boolean,
    },
    color: {
      type: String,
      default: "bg-main4"
    }
  },
  emits: ['update:modelValue'],
  mounted() {
    this.enabled = this.modelValue
  },
  watch: {
    enabled() {
      this.$emit('update:modelValue', this.enabled)
    }
  }
}
</script>