<template>
    <div class="relative">
        <label v-show="label" for="input" class="block text-sm font-medium text-gray-700 text-left mb-1">
            {{ label }}
        </label>

        <template v-if="urls.length > 0">
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 w-full space-x-5">
                <template v-for="(item, index) in urls" :key="index">
                    <template v-if="item.type.match('image.*')">
                        <div class="col-span-1 flex items-center justify-center w-32 h-32">
                            <div class="relative h-32 w-32">
                                <img :src="item.url" alt="BTE" class="object-cover w-full h-full">
                                <button @click="removeImage(index)" type="button"
                                        class="absolute top-0 right-0 text-gray-300 hover:text-red-500">
                                    <TrashIcon class="w-5 h-5" />
                                </button>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="col-span-1 flex items-center">
                            <div class="relative flex items-center px-3 py-2">
                                <p class="mr-2 font-semibold text-base dark:text-gray-300">{{item.name}}</p>
                                <button @click="removeImage(index)" type="button"
                                        class="text-gray-300 hover:text-red-500">
                                    <TrashIcon class="w-5 h-5" />
                                </button>
                            </div>
                        </div>
                    </template>
                </template>
            </div>
        </template>

        <div class="overflow-hidden relative w-auto mt-4 mb-4" :class="loading ? 'pointer-events-none' : ''">
            <button v-if="displayUploadButton" type="button"
                    class="bg-gradient-to-r from-button2-from to-button2-to rounded-lg hover:bg-indigo-dark text-white font-bold py-2 px-4 inline-flex items-center relative">
                <svg :class="loading ? 'opacity-0' : ''" fill="#FFF" height="18" viewBox="0 0 24 24" width="18"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0h24v24H0z" fill="none"/>
                    <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z"/>
                </svg>
                <span class="ml-2" :class="loading ? 'opacity-0' : ''">{{ placeholder }}</span>

                <template v-if="loading">
                    <div class="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                        <Loading/>
                    </div>
                </template>

            </button>
            <input class="cursor-pointer absolute top-0 left-0 block opacity-0 pin-r pin-t" type="file"
                   name="vacancyImageFiles" @change="previewFiles" :multiple="multiple" ref="fileupload">
        </div>

        <p v-if="errors" class="mt-2 text-sm text-red-500 absolute -bottom-5 left-0" id="input2-error">
            {{ errors[0] }}</p>
    </div>
</template>

<script>
import Loading from "@/components/Components/Loading";
import {mapGetters} from 'vuex'

import {
  TrashIcon,
} from "@heroicons/vue/solid";

export default {
    name: "Input",
    components: {
        Loading,
        TrashIcon,
    },
    computed: {
        ...mapGetters({
            loading: 'loading'
        }),
        displayUploadButton: function () {

            if(!this.multiple) {
                return this.urls.length < 1
            }

            return true
        }
    },
    data() {
        return {
            urls: [],
            files: []
        }
    },
    props: {
        label: {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            default: null
        },
        errors: {
            type: Array,
        },
        multiple: {
            type: Boolean,
            default: false
        }
    },
    emits: ['files'],
    methods: {
        previewFiles(event) {
            event.target.files.forEach(file => {
                this.files.push(file);
            });
            this.$emit('files', this.files)

            event.target.files.forEach(file => {
                const url = URL.createObjectURL(file)
                this.urls.push({url: url, name: file.name, type: file.type})
            });
        },
        removeImage(index) {
            this.files.splice(index, 1);
            this.urls.splice(index, 1);

            if (!this.multiple) {
                this.$refs.fileupload.value = null;
                this.files = []                
                this.urls = []                
            }
            this.$emit('files', this.files)
        }
    }
}
</script>

<style>

</style>